
import React from 'react';
import { Link } from 'gatsby';


import Layout from '../../components/layout/Layout';
import FeatureCard from '../../components/FeatureCard';
import featuresData from '../../data/productos/dispensadores/features-data';
import { StaticImage } from "gatsby-plugin-image"
import SplitSection from '../../components/SplitSection';
import SplitSectionB2b from '../../components/SplitSectionB2b';
import LeadForm from '../../components/LeadForm';
import NewsletterSub from '../../components/NewsletterSub'

const siteVerOpts = [

  {
    label: 'Ecuador',
    value: '',


  },

  {
    label: 'Internacional',
    value: '/',


  },
];

const langOpts = [


  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: `/export/en/products`,

  },


];

const seo={
  title: "Dispensadores",
  desc:  "Contamos con una gran variedad de dispensadores modernos y elegantes diseños que se adaptan facilmente a sus necesidades de uso. Ideal para dosificar y economizar el producto.",
  image: "dispensadores.jpg",
  pathname: "/dispensadores",
}

export default function Named ({location}) {


  return(
  <Layout location={location} crumbLabel="Dispensadores"
  siteVerOpts={siteVerOpts} langOpts={langOpts}
  title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>



<section id="Dispensadores" className="mt-14">
              <div className="">
               <h1 className="text-5xl font-semibold text-center mb-2">Dispensadores</h1>
                <h2 className="text-4xl font-semibold text-center mb-8 text-primary-lighter"> amplia línea de dispensadores </h2>
              </div>
            </section>



<section id="productos quimicos descripcion" className="mt-14">
<div className="relative bg-blue-900">
  <div className="h-56 bg-blue-900 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
  <StaticImage src="../../images/dispensadores.jpg"
                      className="flex justify-center "
                      alt="Dispensadores de material resistente para cualquiera de sus necesidades"
                      placeholder="blurred"
                      layout="constrained"
                       />
  </div>
  <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16 pt-28">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
      <p className=" text-xl text-left font-normal text-white pb-6"><ul className="list-disc ml-8">
                <li>Gran variedad de dispensadores modernos y con elegantes diseños que se adaptan fácilmente a sus necesidades de uso.</li>
                <li>Ideales para dosificar el producto químico o absorbente y evitar su desperdicio.</li>
              </ul></p>
      
    </div>
  </div>
</div>
</section>


<section id="categorias productos quimicos" className=" mt-26 bg-gray-200 pt-16 pb-10 ">
  <h3 className="text-primary-default  title-font text-center text-4xl font-semibold  ">Portafolio de Dispensadores</h3>
         
    <div className="relative bg-gray-200 px-4 sm:px-6 lg:pt-6 lg:pb-28 lg:px-8">
     <div className="relative max-w-7xl mx-auto mt-10">
      <div className=" max-w-lg mx-auto grid gap-8 lg:grid-cols-4 lg:max-w-none">

        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden ">
          <div className="flex-1 bg-gray-100 p-6 flex flex-col justify-between">
            <div className="flex-1 ">

                <div className="flex flex-wrap mx-auto justify-center ">
                  <h3 className="text-3xl font-semibold text-secondary-lighter text-center leading-relaxed">Dispensador de Jabón</h3>
                </div>
              
               <div className="flex flex-wrap mx-auto justify-left">
                 <Link to="/productos/dispensadores/jabon-liquido" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Dispensador de jabón líquido 
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
                 <Link to="/productos/dispensadores/jabon-espuma" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Dispensador de jabón espuma
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
                 <Link to="/productos/dispensadores/jabon-liquido-automatico" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold">Dispensador de jabón líquido automático
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
                 <Link to="/productos/dispensadores/jabon-espuma-automatico" className="mt-2 text-primary-lighter inline-flex items-center text-base text-semibold"> Dispensador de jabón espuma automático
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
                 </Link>
              </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-gray-100 p-6 flex flex-col justify-between">
          <div className="flex-1">
              <div className="flex flex-wrap mx-auto justify-center">
                  <h3 className="text-3xl font-semibold text-secondary-lighter text-center leading-relaxed">Dispensador de Gel Antibacterial</h3>
                </div>
              <div className="flex flex-wrap mx-auto justify-left">
                <Link to="/productos/dispensadores/gel-antibacterial" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Dispensador de gel antibacterial
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
               <Link to="/productos/dispensadores/gel-antibacterial-automatico" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Dispensador de gel antibacterial automático
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-gray-100 p-6 flex flex-col justify-between">
          <div className="flex-1">
              <div className="flex flex-wrap mx-auto justify-center ">
                  <h3 className="text-3xl font-semibold text-secondary-lighter text-center">Dispensador de alcohol</h3>
                </div>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/productos/dispensadores/alcohol" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Dispensador de alcohol spray
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div> 
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-gray-100 p-6 flex flex-col justify-between">
          <div className="flex-1">
              <div className="flex flex-wrap mx-auto justify-center ">
                  <h3 className="text-3xl font-semibold text-secondary-lighter text-center">Dispensador de Papel Higiénico</h3>
                </div>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/productos/dispensadores/papel-higienico-jumbo-unilimpio" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Dispensador de papel higiénico jumbo
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
               <Link to="/productos/dispensadores/papel-higienico-jumbo-de-lujo" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Dispensador de papel higiénico jumbo de lujo
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
               <Link to="/productos/dispensadores/papel-higienico-jumbo-premium" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Dispensador de papel higiénico jumbo premium
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div> 
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-gray-100 p-6 flex flex-col justify-between">
          <div className="flex-1">
              <div className="flex flex-wrap mx-auto justify-center ">
                  <h3 className="text-3xl font-semibold text-secondary-lighter text-center">Dispensador de Toallas</h3>
                </div>
              <div className="flex flex-wrap mx-auto justify-left ">
                <Link to="/productos/dispensadores/toalla-z-unilimpio" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Dispensador de toalla Z
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
               <Link to="/productos/dispensadores/toalla-z-de-lujo" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Dispensador de toalla Z de lujo
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
               <Link to="/productos/dispensadores/toalla-center-pull" className="mt-4 text-primary-lighter inline-flex items-center text-base text-semibold">Dispensador de toalla center pull de lujo
                 <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                 <path d="M5 12h14M12 5l7 7-7 7"></path>
                 </svg>
               </Link>
              </div>
          </div> 
        </div>
      </div>

     </div>
    </div>
  
  </div>
</section>





<section id="features" className="py-10 md:py-20 ">
      <div className="container mx-auto">
        <div className="w-full bg-fill bg-no-repeat bg-products">
          <h2 className="text-3xl font-semibold text-center text-blue-900">¿Por qué elegir nuestro dispensadores?</h2>
          <p className="md:w-2/3 mx-auto text-center my-5 text-2xl">
          Al elegirnos, usted recibe los siguientes beneficios:
          </p>
        </div>

        <div className="flex flex-wrap justify-center">

            {featuresData.map(feature => (
            <div key={feature.featureName} className="w-full p-4 lg:w-1/3">
              <FeatureCard feature={feature} />
            </div>
            ))}


        </div>
      </div>
</section>

<SplitSectionB2b/>

<LeadForm/>
<NewsletterSub />



  </Layout>
)}
