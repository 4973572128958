


const databits = [
  {
    title: 'Calidad',
    content:
      'Nuestros dispensadores son fabricados con materiales resistentes de larga duración. ',
    
      featureName: 'dispensadores de calidad',
    
    featureImage: '../../images/IconeMateriaPrima.svg'
  },
  {
    title: 'Variedad',
    content:
      'Contamos con una gran variedad de dispensadores para todo tipo de uso: jabón, gel, absorbentes, ambientales, etc. Nuestros dispensadores cuentan con diseños modernos y elegantes que se adaptan fácilmente a sus necesidades. ',
    
      featureName: 'Variedad de dispensadores',
    
    featureImage: '../../images/IconeVariedad.svg'
  },
  
  {
    title: 'Rendimiento',
    content:
      'Con nuestros dispensadores puede dosificar el producto (químicos o absorbentes) para tener mayor durabilidad y ahorro. ',
     
      featureName: 'rendimiento y ahorro de productos químicos y absorbentes',
    
    featureImage: '../../images/IconeRendimiento.svg'
  }
  
  

];
export default databits;
